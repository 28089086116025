<template>
  <div class="news-page">
    <div class="news-page__news-container">
      <!-- <pre>{{news}}</pre> -->
      <article class="news" v-for="(post) in news" :key="post.id">
        <div class="news__image-container">
          <span class="news__published">{{ post.created_at | date }}</span>
          <div
            class="news__image"
            :style="{ background: `url(${post.poster}) center`, backgroundSize: 'cover',  }"
          >
            <!-- <img :src="post.poster" alt="news"> -->
          </div>
        </div>
        <div class="news__text">
          <h1 class="news__title">{{ post.title }}</h1>

          <p class="news__description" v-html="post.text" @click="showMoreInText"></p>
          <!-- <a class="news__link" @click.prevent="showMore" href="#">Read more</a> -->
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { NewsService } from "../services/news.service";

import moment from "moment";

export default {
  name: "news",

  filters: {
    date(value) {
      const items = value.split(" ");

      return `${items[0]} | ${items[1].slice(0, 5)}`;
    }
  },

  data: () => ({
    news: [],

    limit: 10,
    offset: 0,
    count: 0,
    currentPage: 1,

    articleList: [
      {
        id: 1,
        image: require("../assets/img/news/news_1.jpg"),
        title: "Amazon optimism Over the U.S.-EU Auto Tariffs?",
        created: "Nov 26, 2019",
        text:
          "In this Thoughts on the Market series, Michael Zezas offers perspective on how U.S. public policy affects equity and fixed income markets, including trade tensions, infrastructure and government, policy. Listen to this week’s update. Thoughts on the Market is also available as podcast on Apple... Podcasts, Spotify, Google Play and other... major podcast platforms. In addition, the most recent episode is available on Amazon's Alexa. Just ask Alexa to 'Enable Morgan Stanley' or to 'Add Morgan Stanley to my Flash Briefing.'"
      },
      {
        id: 2,
        image: require("../assets/img/news/news_2.jpg"),
        title: "Apple — WWDC 2019",
        created: "Jun 10, 2019",
        text:
          "Experience a dramatic new look with Dark Mode. Explore fun and functional updates to Photos, Maps, Messages, and more. As well as new features to help you manage your privacy and security..."
      },
      {
        id: 3,
        image: require("../assets/img/news/news_3.jpg"),
        title: "Poor old Microsoft ",
        created: "May 5, 2019",
        text:
          "In this Thoughts on the Market series, Michael Zezas offers perspective on how U.S. public policy affects equity and fixed income markets, including trade tensions, infrastructure and government, policy. Listen to this week’s update. Thoughts on the Market is also available as podcast on Apple... Podcasts, Spotify, Google Play and other... major podcast platforms. In addition, the most recent episode is available on Amazon's Alexa. Just ask Alexa to 'Enable Morgan Stanley' or to 'Add Morgan Stanley to my Flash Briefing.'In this Thoughts on the Market series, Michael Zezas offers perspective on how U.S. public policy affects equity and fixed income markets, including trade tensions, infrastructure and government, policy. Listen to this week’s update. Thoughts on the Market is also available as podcast on Apple... Podcasts, Spotify, Google Play and other... major podcast platforms. In addition, the most recent episode is available on Amazon's Alexa. Just ask Alexa to 'Enable Morgan Stanley' or to 'Add Morgan Stanley to my Flash Briefing.'"
      }
    ]
  }),

  created() {
    this.loadNews();
  },

  methods: {
    showMore(event) {
      event.target.previousSibling.classList.toggle(
        "news__description_visible"
      );
      event.target.classList.toggle("news__link_more");
      if (event.target.text == "Close") {
        setTimeout(() => {
          event.target.text = "Read more";
        }, 300);
      } else {
        setTimeout(() => {
          event.target.text = "Close";
        }, 300);
      }
    },
    showMoreInText(event) {
      if (window.innerWidth <= 1024) {
        event.target.classList.toggle("news__description_visible");
        event.target.nextSibling.classList.toggle("news__link_more");
        if (event.target.nextSibling.text == "Close") {
          setTimeout(() => {
            event.target.nextSibling.text = "Read more";
          }, 300);
        } else {
          setTimeout(() => {
            event.target.nextSibling.text = "Close";
          }, 300);
        }
      }
    },
    async loadNews() {
      try {
        const response = await NewsService.getNews({
          limit: this.limit,
          offset: (this.currentPage - 1) * this.limit
        });

        this.count = response.count;

        this.news = response.results;
      } catch (error) {
        /* this.errorMessage = error.response
        this.showErrorMsg() */
        throw error;
      }
    }
  }
};
</script>
