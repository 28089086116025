import { ApiService } from "./api.service";
// const BASE_URL = "https://api.plazalab.club/";
import { BASE_URL } from "@/utils/constants";

class NewsError extends Error {
  constructor(message, errorCode, statusText) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
    this.errorText = statusText;
  }
}

const NewsService = {
  getNews: async ({
    limit,
    offset,
    received_at,
    service_name,
    number,
    status
  }) => {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/news/`,
      params: {
        limit,
        offset
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new NewsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  }
};

export default NewsService;

export { NewsService, NewsError };
